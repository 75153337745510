import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { isEmpty } from "@helpers";
import { ProfileActions } from "@store/modules/profile/types";
import { profileModule } from "@store/namespaces";
import { VForm } from "@/types/vuetify";
import { AutotestId } from "@/types/autotests";

@Component({
  components: {
    QrCode: () => import("@/components/QrCode/QrCode.vue"),
  },
})
export default class FA2Form extends Vue {
  @profileModule.Action("generateSecondFactor")
  private readonly generateSecondFactorAction!: ProfileActions["generateSecondFactor"];
  @profileModule.Action("enableSecondFactor")
  private readonly enableSecondFactorAction!: ProfileActions["enableSecondFactor"];
  @profileModule.Action("disableSecondFactor")
  private readonly disableSecondFactorAction!: ProfileActions["disableSecondFactor"];
  @profileModule.Action("fetchProfile")
  private readonly fetchProfileAction!: ProfileActions["fetchProfile"];

  @Ref("form") private readonly formRef!: VForm;
  @Prop({ type: Boolean, default: false }) private readonly enabled!: boolean;
  @Prop({ type: Boolean, default: true }) private readonly active!: boolean;

  private loadingSecret = false;
  private loadingSecondFactor = false;
  private secret = "";
  private code = "";
  private formKey = Date.now();

  private get autoTestId() {
    return {
      codeTextField: AutotestId.SECOND_FACTOR_CODE_TEXT_FIELD,
      submitButton: AutotestId.SECOND_FACTOR_SUBMIT_BUTTON,
    };
  }

  private get isShowQrCode() {
    return !this.enabled;
  }

  private get submitButtonProps() {
    if (this.enabled) {
      return {
        color: "error",
        text: this.$vuetify.lang.t("$vuetify.fields.disable"),
      };
    }

    return {
      color: "primary",
      text: this.$vuetify.lang.t("$vuetify.fields.connect"),
    };
  }

  private get rulesFields() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
    };
  }

  private async generateSecondFactor() {
    if (this.loadingSecret || !this.isShowQrCode) return;

    this.loadingSecret = true;

    try {
      this.secret = await this.generateSecondFactorAction();
    } catch {
      this.secret = "";
    }

    this.loadingSecret = false;
  }

  private async onSubmitForm() {
    const validForm = this.formRef.validate();

    if (!validForm || this.loadingSecondFactor) return;

    this.loadingSecondFactor = true;
    if (!this.enabled) {
      try {
        await this.enableSecondFactorAction({
          code: this.code,
          secret: this.secret,
        });

        await this.fetchProfileAction();

        this.$emit("enable:success");

        this.loadingSecondFactor = false;
      } catch (error) {
        this.loadingSecondFactor = false;
      }
    } else {
      try {
        await this.disableSecondFactorAction({ code: this.code });
        await this.fetchProfileAction();

        this.$emit("disable:success");
        this.loadingSecondFactor = false;
      } catch (error) {
        this.loadingSecondFactor = false;
      }
    }
  }

  private mounted() {
    this.$watch(
      "active",
      (active = this.active) => {
        this.formKey++;
        if (!active) {
          this.code = "";
        } else if (!this.secret) {
          this.generateSecondFactor();
        }
      },
      { immediate: true }
    );
  }
}
